<template>
  <div class="content">
    <img src="@/assets/images/result.png" alt="">
    <p>恭喜您,提交成功!</p>
  </div>
</template>
<style lang="less" scoped>
.content{
  background-image: url('../assets/images/blue_bgi.png');
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:  center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 33px;
  font-weight: 500;
  color: #333;
  img{
    width: 50%;
    margin-bottom:40px;
  }
}
</style>